import axios from 'axios';

export const defaultApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const baseHeaders = (othersHeaders = {}) => ({
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
    ...othersHeaders,
  },
});

export const Get = (url, params, headers) => {
  return defaultApi.get(url, {params, ...baseHeaders(headers)})
    .then(data => data.data)
    .catch(error => {
      throw error;
    });
};

export const Post = (url, data, headers) => {
  return defaultApi.post(url, data, baseHeaders(headers))
    .then(d => d.data)
    .catch(error => {
      throw error;
    });
};

export const Put = (url, data, params, headers) => {
  return defaultApi.put(url, data, {
    params,
    ...baseHeaders(headers),
  })
    .then(d => d.data)
    .catch(error => {
      throw error;
    });
};

export const Delete = (url, data, headers) => {
  return defaultApi.delete(url, {data, headers: baseHeaders(headers).headers})
    .then(d => d.data)
    .catch(error => {
      throw error;
    });
};

export const Patch = (url, data, headers) => {
  return defaultApi.patch(url, data, baseHeaders(headers))
    .then(d => d.data)
    .catch(error => {
      throw error;
    });
};
