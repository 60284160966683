import {connectRouter} from 'connected-react-router';
import {combineReducers} from 'redux';

import {reducer as user} from '../pages/User/store/reducer';
import {reducer as transaction} from '../pages/Transactions/store/reducer'
import {reducer as dashboard} from '../pages/Dashboard/store/reducer'
import {reducer as sellers} from '../pages/Sellers/store/reducer'


const RootReducer = (hist) => combineReducers({
  router: connectRouter(hist),
  user,
  transaction,
  dashboard,
  sellers,
});

export default RootReducer;
