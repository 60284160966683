import styled from 'styled-components'
import { CardContent, Skeleton } from '@mui/material'

export const CardContainer = styled(CardContent)`
    font-family: 'Montserrat', sans-serif;
`;

export const DashTitle = styled.h3`
    margin-bottom: 30px;
    color: #6e6b7b;
    font-weight: 400;
`;

export const DashItems = styled.section`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    transition: 0.5s ease-in-out;

    @media screen and (max-width: 940px){
        flex-direction: column;
        transition: 0.5s ease-in-out;
    }

    @media screen and (max-width: 740px){
        align-items: center;
        transition: 0.5s ease-in-out;
        justify-content: center;
        width: 410px;
    }
    
`;

export const DashItem = styled.div`
    width: 100%; // 400px quando tiver chargeback
    height: 130px;
    background-color: #fff;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 940px){
        height: 130px;
    }
`;

export const DashIcon = styled.div`
    width: 65px;
    height: 65px;
    background: ${({bgColor, loading}) => loading ? `linear-gradient(
        120deg, 
        #e5e5e5 30%, 
        #f0f0f0 38%, 
        #f0f0f0 40%, 
        #e5e5e5 48%)` : bgColor};
    background-size: ${({loading}) => loading && '200% 300%'};
    background-position: ${({loading}) => loading && 100% 0};
    animation: load 2s linear infinite;
    @keyframes load {
        100%{
            background-position: -100% 0;
        }
    };
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
`;

export const DashValue = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
`;

export const DashNumber = styled.span`
    font-size: 30px;
    font-size: ${({fontSize}) => fontSize ? `${fontSize}px` : `30px`};
    font-weight: 500;
    color: #6e6b7b;

`;

export const DashLabel = styled.span`
    font-weight: 500; 
    font-size: 1rem;
    color: #6e6b7b;
    line-height: 20px;
`;

export const DashData = styled.section`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    @media screen and (max-width: 1465px){
        flex-wrap: wrap;
    }

`;

export const DashGrafic = styled.div`
    width: 700px;
    height: 450px;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    color: #6e6b7b;

    @media screen and (max-width: 940px){
        width: 330px;
        margin-left: 10px;
        margin-bottom: 30px;
    }

    @media screen and (max-width: 940px){
        width: 380px;
    }
`;

export const DashPaymentType = styled.div`
    width: 390px;
    height: 470px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    margin-left: 25px;
    margin-right: 10px;
    overflow: hidden;

    @media screen and (max-width: 940px){
        width: 350px;
        margin-left: 10px;
        margin-bottom: 30px;
    }

    @media screen and (max-width: 940px){
        width: 400px;
        margin-left: 0px;
    }
`;

export const DashSellerChanel = styled.div`
    width: 390px;
    height: 470px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    margin-left: 10px;
    margin-right: 10px;
    overflow-x: hidden; /* Hide horizontal scrollbar */
    overflow-y: ${({items}) => items >= 7 ? 'scroll' : ''}; /* Add vertical scrollbar */
    padding-right: 10px;
    scrollbar-width: thin;
    scrollbar-color: black transparent;

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #D4D4D4;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #6e6b7b;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #494362;
    }
`;

export const FlatDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FlatIcon = styled.img``;

export const FlatTitle = styled.span`
    margin-left: 10px;
    color: #6e6b7b;
    font-size: 16px;
`;

export const FlatValue = styled.span`
    color: #37ca79;
    font-weight: 700;
`;

export const DashPaymentHeader = styled.div`
    width: 100%; 
    height: 30px; 
    text-align: center;
`;

export const DashPaymentTitle = styled.h4`
    color: #6e6b7b; 
    margin: 10px;
    margin-top: 20px;
`;

export const DashPaymentBody = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const DashPaymentElement = styled.div`
    width:100%; 
    height: 40px; 
    display: flex; 
    justify-content: center; 
    align-items: flex-start;
    flex-direction: column;
    margin-top: ${({marginT}) => marginT ? `${marginT}px` : '30px'} ;
    margin-bottom: 30px;
    margin-left: 10px;
`;

export const DashPaymentTypeElement = styled.div`
    margin: 10px; 
    width: 310px; 
    display:flex; 
    justify-content: space-between;
    align-items: center; 
    margin-bottom: 0px; 
    margin-top: 0px;
`;

export const CardText = styled.div`
    width: 100%; 
    height: 70px; 
    display: flex; 
    justify-content: space-between; 
    align-items: center;
`;

export const TextUser = styled.span`
    color: #6e6b7b; 
    font-size: 1.276rem; 
    font-weight: 700;
`;

export const DashGraficText = styled.div`
    margin: 10px 0px 25px 10px; 
    display: flex; 
    justify-content: space-between; 
    align-items: center;
`;

export const FlatItem = styled.div`
    width: 40px;
    height: 40px; 
    background-color: #e5e3f6;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
`;

export const DashLoading = styled.div`
    width: 180px; 
    height: 20px; 
    border-radius: 8px; 
    margin-left: 29px;
    background: linear-gradient(
        120deg,
        #e5e5e5 30%,
        #f0f0f0 38%,
        #f0f0f0 40%,
        #e5e5e5 48%
    );
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: load 2s linear infinite;
    @keyframes load {
        100%{
            background-position: -100% 0;
        }
    }
    margin-bottom: ${({marginB}) => `${marginB}px`};
`;

export const DashLoadingGrafic = styled.div`
    width: 180px; 
    height: 20px; 
    border-radius: 8px; 
    background: linear-gradient(
        120deg,
        #e5e5e5 30%,
        #f0f0f0 38%,
        #f0f0f0 40%,
        #e5e5e5 48%
    );
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: load 2s linear infinite;
    @keyframes load {
        100%{
            background-position: -100% 0;
        }
    }
    margin-bottom: ${({marginB}) => `${marginB}px`};

`
export const SkeletonGrafic = styled(Skeleton)`
    margin-top: 62px;
    margin-left: 62px;

    @media screen and (max-width: 940px){
        margin-left: 28px;
    }
`;

export const SkeletonGraficPie = styled(Skeleton)`
    margin-top: 62px;
`;

export const SkeletonText = styled.div`
    width: 75%; 
    height: 25px;
    border-radius: 8px; 
    margin-top: 25px;
    margin-left: 48.5px;
    background: linear-gradient(
        120deg,
        #e5e5e5 30%,
        #f0f0f0 38%,
        #f0f0f0 40%,
        #e5e5e5 48%
    );
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: load 2s linear infinite;
    @keyframes load {
        100%{
            background-position: -100% 0;
        }
    }
    margin-bottom: ${({marginB}) => `${marginB}px`};
    
`;

export const SkeletonValue = styled.div`
    width: 90px; 
    height: 20px;
    border-radius: 8px; 
    background: linear-gradient(
        120deg,
        #e5e5e5 30%,
        #f0f0f0 38%,
        #f0f0f0 40%,
        #e5e5e5 48%
    );
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: load 2s linear infinite;
    @keyframes load {
        100%{
            background-position: -100% 0;
        }
    }
    margin-bottom: ${({marginB}) => `${marginB}px`};
    
`;

export const SkeletonTransactionbyDay = styled.div`
    width: 150px; 
    height: 20px;
    border-radius: 8px; 
    margin-right: 10px;
    background: linear-gradient(
        120deg,
        #e5e5e5 30%,
        #f0f0f0 38%,
        #f0f0f0 40%,
        #e5e5e5 48%
    );
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: load 2s linear infinite;
    @keyframes load {
        100%{
            background-position: -100% 0;
        }
    }
    margin-bottom: ${({marginB}) => `${marginB}px`};
    
`;

export const UserLoading = styled.div`

    width: 250px;

    height: 30px;

    border-radius: 8px;

    background: linear-gradient(

        120deg,

        #e5e5e5 30%,

        #f0f0f0 38%,

        #f0f0f0 40%,

        #e5e5e5 48%

    );

    background-size: 200% 100%;

    background-position: 100% 0;

    animation: load 1.5s infinite;

    margin-bottom: 10px;


    @keyframes load {

        100%{

            background-position: -100% 0;

        }

    }

`;
