import React, { useEffect } from 'react'
import {
    Datagrid, 
    ShowButton,
    FilterButton,
    FunctionField, 
    List, 
    TextField, 
    TopToolbar,
    Pagination,
    TextInput,
    ExportButton,
} from 'react-admin'
import { sellersSelectors } from '../store/reducer.js';
import { useSelector } from 'react-redux'; 
import { useTranslation } from 'react-i18next';
import './Sellers.css'
import Loading from 'src/components/Loading';
import { SellersSpan, BadgeList,} from './SellersList.Elements.js';
import { formatDocument } from 'src/utils/formatters.js';
import { useDispatch } from "react-redux";
import { sellerActions } from "../store/reducer";
import { ContainerFilter, ContainerItem, ContainerLabel } from 'src/components/StyleFilters/StyleFilters.Elements.js';

const SellersList = () => {
  const {t} = useTranslation();
  const loading = useSelector(sellersSelectors.getLoading);

  const dispatch = useDispatch();

    useEffect(()=> {
        dispatch(sellerActions.getSellersList())

    }, [dispatch])

  const SellersFilters = [
      <FunctionField source='id' label={t('Sellers.id')} render={() => (
          <ContainerFilter>
              <ContainerLabel>{t('Sellers.id')}</ContainerLabel>
              <ContainerItem>
                  <TextInput source="id" label={t('Sellers.id')} />
              </ContainerItem>
          </ContainerFilter>
        )} />,
      <FunctionField source='name' label={t('Sellers.name')} render={() => (
          <ContainerFilter>
              <ContainerLabel>{t('Sellers.name')}</ContainerLabel>
              <ContainerItem>
                  <TextInput source="name" label={t('Sellers.name')} />
              </ContainerItem>
          </ContainerFilter>
        )} />,
      <FunctionField source='fantasyName' label={t('Sellers.fantasyName')} render={() => (
          <ContainerFilter>
              <ContainerLabel>{t('Sellers.fantasyName')}</ContainerLabel>
              <ContainerItem>
                  <TextInput source="fantasyName" label={t('Sellers.fantasyName')} />
              </ContainerItem>
          </ContainerFilter>
          )} />,
      <FunctionField source='document' label={t('Sellers.document')} render={() => (
          <ContainerFilter>
              <ContainerLabel>{t('Sellers.document')}</ContainerLabel>
              <ContainerItem>
                  <TextInput source="document" label={t('Sellers.document')} />
              </ContainerItem>
          </ContainerFilter>
          )} />,
    ];

    const ListActions = () => {
        return(
            <TopToolbar className='topToolbar'>
                <FilterButton source="full_name" />
                <ExportButton />
            </TopToolbar>
        )
    }

  return (
      <div>
          {loading ? <Loading /> :
          <List debounce={500} actions={<ListActions/>} pagination={false} filters={SellersFilters} emptyWhileLoading >
              <>
                  <Datagrid size={"medium"} bulkActionButtons={false} rowClick= "show">
                      <TextField label={t('Sellers.id')} source="id" />
                      <TextField label={t('Sellers.documentType')} source="documentType" />
                      <FunctionField sortBy={'document'} label={t('Sellers.document')} render={record =>  
                          <SellersSpan>{formatDocument(record.document)} </SellersSpan> } />
                      <TextField label={t('Sellers.name')} source="name" /> 
                      <TextField label={t('Sellers.fantasyName')} source="fantasyName" />
                      <FunctionField sortBy={'active'} label={t('Sellers.status')} render={record => record.active ? <BadgeList background="green">{t('Sellers.active')}</BadgeList> : <BadgeList background="red">{t('Sellers.inactive')}</BadgeList>} />
                      <ShowButton label={t('Sellers.moreDetails')}/>
                  </Datagrid>
                  <Pagination labelRowsPerPage="Itens por página:" />
              </>
          </List> 
            }
      </div>
  )
}
export default SellersList
