import styled from 'styled-components';

export const ExportButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1976d2;
    text-decoration: none;
    font-size: 14px;
    font-weight: 300;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    width: 100px;
    height: 30px;
    cursor: pointer;
    text-transform: uppercase;

    &:hover{
        background-color: #f0f5f8;
    }
`;