import axios from 'axios';
import {useCallback, useEffect, useState} from 'react' //eslint-disable-line
import { useDispatch } from 'react-redux';
import { TransactionActions } from 'src/pages/Transactions/store/reducer';

export function useExport(){
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const handleExport = useCallback(async () => {
    dispatch(TransactionActions.initLoadingExport());
    try{
      const config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/transactions/report.xlsx`, config);
      dispatch(TransactionActions.asyncExport(response?.data));
      dispatch(TransactionActions.endLoadingExport());
      return response?.data;
    } catch(error){
      console.log(error)
    } 
  }, [])

  return handleExport
}