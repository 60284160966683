import React, { useEffect}  from "react";
import { useSelector } from "react-redux";
import { 
    Show, 
    SimpleForm, 
    Toolbar, 
    TabbedShowLayout, 
    Tab, 
    TextField, 
    FunctionField,
    TabbedShowLayoutTabs, 
    } from 'react-admin' 
import { BadgeList } from "src/pages/User/UserList/UserList.Elements";
import { formatDocument } from "src/utils/formatters";
import { SellersSpan } from "./SellersDetails.Elements";
import { useTranslation } from 'react-i18next';
import { TransLink } from "src/pages/Transactions/TransactionsShow/TransactionsShow.Elements";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import './SellersDetails.css'
import { sellersSelectors, sellerActions } from "../store/reducer";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useWidth from "src/hooks/useWidth";

const SellersDetails = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams();
    const seller = useSelector(sellersSelectors.getSellersById)
    const mobileSize = 820;
    // utilizar fantasy name apos api estiver ok para nao exibir fantasy name quando o seller nao tiver. implementar loading caso necessario
    console.log(seller.fantasyName) 
    const width  = useWidth();
    // console.log(width)


    useEffect(()=> {
        dispatch(sellerActions.getSellersById(id))

    }, [dispatch])

    const TransactionToolbar = () => {
        return (
            <Toolbar>
                <TransLink to="/sellers">{t('Sellers.back')}</TransLink>
            </Toolbar>
        );
    };

    return (
        <div>
            <Show title={t('Sellers.details')}>
                <SimpleForm toolbar={<TransactionToolbar />}>
                    <TabbedShowLayout tabs={ width < mobileSize ? <TabbedShowLayoutTabs scrollButtons="true" variant="fullWidth"/> : <TabbedShowLayoutTabs/>}>
                        <Tab label="Vendedor" name="sellers">
                            <TextField label={t('Sellers.id')} source="id" />
                            <TextField label={t('Sellers.sellerId')} source="sellerId" />
                            <TextField label={t('Sellers.documentType')} source="documentType" />
                            <FunctionField label={t('Sellers.document')} render={record =>  
                                <SellersSpan>{formatDocument(record.document)} </SellersSpan> }
                                />
                            <TextField label={t('Sellers.name')} source="name" /> 
                            <FunctionField label={t('Sellers.fantasyName')} render={record => record.fantasyName ? 
                                <TextField source='fantasyName' /> : <TextField source='name'/> }
                                />
                        </Tab>
                        <Tab label={t('Sellers.braspagStatus')} name="braspag">
                            <FunctionField label={t('Sellers.cadastralSituation')} render={record => record.cadastralSituation ? <BadgeList background="green">{t('Sellers.active')}</BadgeList> : <BadgeList background="red">{t('Sellers.inactive')}</BadgeList>} />
                            <TextField label={t('Sellers.sellerId')} source="braspagStatus.SellerId" />
                        </Tab>
                        <Tab label={t('Sellers.bankData')} name="bankData">
                            <TextField label={t('Sellers.bank')} source="bankData.bank" />
                            <TextField label={t('Sellers.branch')} source="bankData.branch" />
                            <TextField label={t('Sellers.branchDigit')} source="bankData.branchDigit" />
                            <TextField label={t('Sellers.account')} source="bankData.account" />
                            <TextField label={t('Sellers.accountDigit')} source="bankData.accountDigit" />
                            <TextField label={t('Sellers.documentType')} source="bankData.documentType" />
                            <TextField label={t('Sellers.holderDocument')} source="bankData.holderDocument" />
                        </Tab>
                        <Tab label={t('Sellers.contracts')} >
                            <TextField label={t('Sellers.contracts')} source="contracts" />
                        </Tab>
                        <Tab label={t('Sellers.salesChannel')}>
                            {/* alterar */}
                            <FunctionField className='formControlLabel' render={record => record.salesChannel.zurich ? 
                                <FormControlLabel  control={<Checkbox defaultChecked disabled />} label={'Zurich'} /> 
                                    : <FormControlLabel disabled control={<Checkbox disabled />} label={t('Sellers.pix')} />
                                }/>
                            <FunctionField className='formControlLabel' render={record => record.salesChannel.santaClara ? 
                                <FormControlLabel control={<Checkbox defaultChecked disabled />} label={t('Sellers.bankSlip')} /> 
                                    : <FormControlLabel disabled control={<Checkbox disabled />} label={'Santa Clara'} />
                                }/>
                            {/* <FunctionField className='formControlLabel' render={record => record.salesChannel.creditcard ? 
                                    <FormControlLabel control={<Checkbox defaultChecked disabled />} label={t('Sellers.crediCard')} /> 
                                    : <FormControlLabel disabled control={<Checkbox disabled />} label={t('Sellers.creditCard')} />
                                }/> */}
                        </Tab>
                    </TabbedShowLayout>
                </SimpleForm>
            </Show>
        </div>
    )
}
export default SellersDetails