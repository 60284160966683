import * as React from "react";
import { useSelector } from "react-redux";
import { UserSelectors } from "../User/store/reducer";

import { useTranslation } from "react-i18next";

import {
  CardContainer,
  DashTitle,
  DashItems,
  DashItem,
  DashIcon,
  DashLabel,
  DashNumber,
  DashValue,
  DashData,
  DashGrafic,
  DashPaymentType,
  DashSellerChanel,
  DashPaymentHeader,
  DashPaymentTitle,
  DashPaymentBody,
  CardText,
  TextUser,
  DashGraficText,
  DashPaymentElement,
  DashPaymentTypeElement,
  FlatDiv,
  FlatTitle,
  FlatValue,
  DashLoading,
  SkeletonGraficPie,
  SkeletonText,
  SkeletonValue,
  SkeletonGrafic,
  SkeletonTransactionbyDay,
  UserLoading,
} from './Dashboard.Elements'
import TrendingUpSharpIcon from '@mui/icons-material/TrendingUpSharp';
// import LoopIcon from '@mui/icons-material/Loop';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { BarChart, Bar, Cell, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, PieChart, Pie } from 'recharts';
import { useState } from "react";
import dayjs from "dayjs";
import { useGetPermissions } from "src/hooks/usePermissions";
import { toCurrency } from "src/utils/formatters";
import { DashboardSelectors } from "./store/reducer";
import { useDashboard } from "src/hooks/useDashboard";

const month = ['Janeiro', 'Feveireiro', 'Março', 'Abril','Maio', 'Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'];

// eslint-disable-next-line react/display-name
export default () => {
    const permissions = useSelector(UserSelectors.getPermissions);
    const user = useSelector(UserSelectors.getUser);
    const {t} = useTranslation();
    const today = dayjs(new Date()).format('YYYY-MM')
    const [filter, setFilter] = useState(today);
    const handleDashboard = useDashboard(dayjs(filter).format('DD'), dayjs(filter).format('MM'));
    const dashboard = useSelector(DashboardSelectors.getDashboard);
    const loading = useSelector(DashboardSelectors.getLoading);

    const handlePermissions = useGetPermissions();

    React.useEffect(() => {
        handleDashboard();
        if (permissions.length === 0) {
            handlePermissions();
        } else {
            return
        }
    }, [filter]);

    const data01 = [
        { name: "Pix", value: 40000 },
        { name: "Boleto", value: 30000 },
        { name: "Lio", value: 3000 },
        { name: "Cartão online", value: 20000 },
    ];



  const COLORS = ['#7276F7', '#30C39F', '#FCCA69', '#FF9373'];

  const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text style={{fontSize: '14px', dispaly: 'flex', justifyContent: 'center', alignItems: 'center'}} x={x} y={y} fill={COLORS[index % COLORS.length]} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="auto">
            {value > 0 && `${toCurrency(value)}`}
        </text>
    );
    };
      

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
        return (
            <div style={{
                backgroundColor: 'white', 
                width: '120px', 
                height: '50px',
                boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                color: '#37ca79',
                flexDirection: 'column',
                paddingLeft: '10px',
            }}> 
                <p style={{ color: payload[0]?.payload?.fill }}>{payload[0]?.name}</p>
                <p className="label">{toCurrency(payload[0].value)}</p>
            </div>
          );
        }
      
        return null;
      };

      const CustomTooltipBar = ({ active, payload }) => {
        if (active && payload && payload.length) {
        return (
            <div style={{
                backgroundColor: 'white', 
                width: '120px', 
                height: '50px',
                boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                flexDirection: 'column',
                paddingLeft: '10px',
                border: '2px solid #ccc'
            }}> 
                <p style={{ color: payload[0]?.payload?.fill }}>Dia: {payload[0]?.payload?.dia}</p>
                <p className="label">{toCurrency(payload[0]?.payload?.valor)}</p>
            </div>
          );
        }
      
        return null;
      };


      const renderLegend = (props) => {
        const { payload } = props;
      
        return (
            <ul style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0px 10px'}}>
                {
                    payload.map((entry, index) => (
                        <div key={index} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '15px'}}>
                            <div style={{width: '10px', height: '10px', backgroundColor: payload[index]?.color, borderRadius: '50%', marginRight: '10px'}} />
                            <li key={`item-${index}`}>{entry.value}</li>
                        </div>
                    ))
                }
            </ul>
        );
      }

return (
    <CardContainer>
        <CardText>
            {loading ? <UserLoading />  : <DashTitle>Seja bem-vindo(a), <TextUser>{user?.name}</TextUser>🎉</DashTitle>}
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '0px', justifyContent: 'center', alignItems: 'center'}}>
                <span style={{fontSize: '14px', color: '#6e6b7b', marginRight: '10px'}}>Filtro de data</span>
                <input style={{
                    width: '150px', 
                    height: '30px', 
                    padding: '3px', 
                    borderRadius: '8px', 
                    border: '1px solid #e5e5e5', 
                    cursor: 'pointer',
                    paddingLeft: '10px',
                    color: '#6e6b7b'
                }} type="date" value={filter} onChange={e => setFilter(e.target.value)} />
            </div>
        </CardText>

        <DashItems>
            <DashItem loading={loading}>
                <DashIcon loading={loading} bgColor="#AFF4BD">
                    {!loading && <TrendingUpSharpIcon sx={{ fontSize: 30, color: 'green', fontWeight: 'bold' }} />}
                </DashIcon>
                <DashValue>
                    {loading ? <DashLoading marginB={10} /> : <DashNumber>{dashboard?.amouthDay ? toCurrency(dashboard?.amouthDay) : toCurrency(0)}</DashNumber>}
                    {loading ? <DashLoading /> : <DashLabel>{t('Transactions.transacionValueDay')}</DashLabel>}
                </DashValue>
            </DashItem>

            <DashItem>
                <DashIcon loading={loading} bgColor="#AFF4BD">
                    {!loading && <CalendarMonthIcon sx={{ fontSize: 30, color: 'green', fontWeight: 'bold' }} />}
                </DashIcon>
                <DashValue>
                    {loading ? <DashLoading marginB={10} /> : <DashNumber>{toCurrency(dashboard?.amountMouth)}</DashNumber>}
                    {loading ? <DashLoading marginB={10} /> : <DashLabel>{t('Transactions.transacionValueMonth')}</DashLabel>}
                </DashValue>
            </DashItem>

            {/* <DashItem>
                <DashIcon loading={loading} bgColor="#EAB2B5">
                    {!loading && <LoopIcon sx={{ fontSize: 30, color: '#AD1C25', fontWeight: 'bold' }} />}
                </DashIcon>
                <DashValue>
                    {loading ? <DashLoading marginB={10} /> : <DashNumber>12</DashNumber>}
                    {loading ? <DashLoading marginB={10} /> : <DashLabel>{t('Transactions.chargebackMonthQTD')}</DashLabel>}
                </DashValue>
            </DashItem>

            <DashItem>
                <DashIcon loading={loading} bgColor="#AFF4BD">
                    {!loading && <AttachMoneyIcon sx={{ fontSize: 30, color: 'green', fontWeight: 'bold' }} />}
                </DashIcon>
                <DashValue>
                    {loading ? <DashLoading marginB={10} /> : <DashNumber fontSize={25}>R$ 1.250,00</DashNumber>}
                    {loading ? <DashLoading marginB={10} /> : <DashLabel>{t('Transactions.chargebackMonthValue')}</DashLabel>}
                </DashValue>
            </DashItem> */}
        </DashItems>

        <DashData>
            <DashGrafic loading={loading}>
                <DashGraficText>
                    {loading ? <SkeletonTransactionbyDay/> :
                    <h3>Transações diárias</h3>
                    }
                    {loading ? <SkeletonTransactionbyDay/> :
                    <h3>{month[dayjs(filter).month()]}/{dayjs(filter).format('YYYY')}</h3>
                    }
                </DashGraficText>
                {loading ? <SkeletonGrafic variant="rounded" sx={{ borderRadius: "10px", width: "85%"}} height={300} /> : 
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart width={550} height={300} data={dashboard?.valuesDayArray} margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 50,
                        }}> 
                        <XAxis dataKey="dia" />
                        <YAxis/>
                        <Tooltip content={CustomTooltipBar} />
                        <Bar dataKey="valor" fill="#8884d8" />
                    </BarChart>
                </ResponsiveContainer>
                }
            </DashGrafic>

            <DashPaymentType>
                <DashPaymentHeader>
                    {loading ? <SkeletonText variant="text"/> :
                    <DashPaymentTitle>Transações por método de pagamento</DashPaymentTitle>
                    }
                </DashPaymentHeader>

                <DashPaymentBody>
                    {loading ? <SkeletonGraficPie variant="circular" width={250} height={250} /> :
                    <ResponsiveContainer width="100%" height="80%">
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart width={400} height={400}>
                                <Pie
                                    data={dashboard?.valuesTypeArray}
                                    cx="55%"
                                    cy="50%"
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={90}
                                    fill="#8884d8"
                                    dataKey="valor"
                                >
                                    {data01.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip content={CustomTooltip} />
                                <Legend iconSize={8} align="center" content={renderLegend} />
                            </PieChart>
                        </ResponsiveContainer>
                    </ResponsiveContainer>
                            }   
                </DashPaymentBody>
            </DashPaymentType>

            <DashSellerChanel items={dashboard?.valuesSalesChannelArray?.length}>
                <DashPaymentHeader>
                    {loading ? <SkeletonText variant="text"/> :
                    <DashPaymentTitle>Transações por canal de venda</DashPaymentTitle>
                    }
                </DashPaymentHeader>

                {dashboard?.valuesSalesChannelArray?.map((item, idx) => {
                    return(
                        <div key={idx}>
                            <DashPaymentElement>
                                <DashPaymentTypeElement>
                                    {loading ? <DashLoading/> :
                                    <FlatDiv>
                                        <FlatTitle>{item?.salesChannel}</FlatTitle>
                                    </FlatDiv>
                                     }
                                    {loading ? <SkeletonValue/> :
                                    <FlatValue>{toCurrency(item?.valor)}</FlatValue>
                                     }
                                </DashPaymentTypeElement>
                            </DashPaymentElement>
                        </div>
                    )
                })}

            </DashSellerChanel>
            
        </DashData>
    </CardContainer>
  )
};
