import React, { useEffect } from 'react'
import { Edit, SimpleForm, Toolbar, useRecordContext } from 'react-admin' 
import { Grid, Box, Typography } from '@mui/material'; 

import {
    TransShowText, 
    TitleField, 
    TransBox, 
    TransTypography,
    TransFunction,
    TransSpan, 
    TransLink,
    Container,
    History,
    // HistoryButtons,
    // EstornoTotal, 
    TableCellRight,
    Line, 
    Date, 
    Seta, 
    Status,
    TimeLine,
    Dates,
    StatusBody,
    HistoryResponsiva,
    Border,
    ToolbarContainer
} from './TransactionsShow.Elements'
import Aside from 'src/components/Aside'; //eslint-disable-line
import { useTranslation } from 'react-i18next';
import TransactionsPix from 'src/components/TransactionsPix'; 
import dayjs from 'dayjs'; 
import TransactionsBoleto from 'src/components/TransactionsBoleto';
import TransactionsCartao from 'src/components/TransactionCartao';
import TransactionsLio from 'src/components/TransactionsLio';
import TransactionInvalid from 'src/components/TransactionInvalid';
import AccessTimeIcon from '@mui/icons-material/AccessTime'; 
import { useStatus } from 'src/hooks/useStatus';
import { useHistory } from 'src/hooks/useHistory';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TransactionSelectors } from '../store/reducer';


const TransactionsShow = () => {
    const { t } = useTranslation();
    const getStatus = useStatus();
    const params = useParams();
    const getHistory = useHistory(params?.id);
    const history = useSelector(TransactionSelectors.getHistory);
    console.log(history);

    useEffect(() => {
        getHistory();
    }, [params]);


    const UserTitle = () => {
        return <span>Transaction Show</span>;
    };

    const TransactionToolbar = () => {
        return (
            <div>

                <div></div>

                <ToolbarContainer>
                    <Toolbar>
                        <TransLink to="/transactions">{t('Transactions.back')}</TransLink>
                    </Toolbar>
                </ToolbarContainer>
            </div>
        );
    };

    // const HandleEstornoTotal = () => {
    //     return console.log('estorno total')
    // }

    const TransactionsDetails = () => { //eslint-disable-line
        const record = useRecordContext();
        switch(record.transactionType){
            case 'pix':
                return (
                    <Container>
                        <TransactionsPix 
                            TransTypography={TransTypography} 
                            TransBox={TransBox}
                            TitleField={TitleField}
                            TransShowText={TransShowText}
                            TransFunction={TransFunction}
                            TransSpan={TransSpan}
                            TableCellRight={TableCellRight}
                        />
                    </Container>
                );
            case 'boleto': 
                return (
                    <Container>
                        <TransactionsBoleto 
                            TransTypography={TransTypography} 
                            TransBox={TransBox}
                            TitleField={TitleField}
                            TransShowText={TransShowText}
                            TransFunction={TransFunction}
                            TransSpan={TransSpan}
                            TableCellRight={TableCellRight}
                        />
                    </Container>
                );
            case 'cartao':
                return (
                    <Container>
                        <TransactionsCartao 
                            TransTypography={TransTypography} 
                            TransBox={TransBox}
                            TitleField={TitleField}
                            TransShowText={TransShowText}
                            TransFunction={TransFunction}
                            TransSpan={TransSpan}
                            TableCellRight={TableCellRight}
                        />
                    </Container>
                );
            case 'lio':
                return (
                    <Container>
                        <TransactionsLio 
                            TransTypography={TransTypography} 
                            TransBox={TransBox}
                            TitleField={TitleField}
                            TransShowText={TransShowText}
                            TransFunction={TransFunction}
                            TransSpan={TransSpan}
                            TableCellRight={TableCellRight}
                        />
                    </Container>
                )
            case 'Transação inválida':
                return (
                    <Container>
                        <TransactionInvalid 
                            TransTypography={TransTypography} 
                            TransBox={TransBox}
                            TitleField={TitleField}
                            TransShowText={TransShowText}
                            TransFunction={TransFunction}
                            TransSpan={TransSpan}
                            TableCellRight={TableCellRight}
                        />
                    </Container>
                )
            default:
                return;
        }
    }

    const HistoryData = () => { //eslint-disable-line
        const keys = Object.keys(history);
        

        return(
            <History marginBottom={keys.length}>
                <TransTypography>{t('TransactionsDetails.history')}</TransTypography>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Line height={keys.length}/>
                    <Grid display="flex" flexDirection={'column'} container rowSpacing={1} columnSpacing={1}>
                        {keys.map((value, idx) => {
                        return(
                            <Grid 
                                item 
                                width={'60%'}
                                minWidth={'300px'}
                                xs={0} 
                                display="flex" 
                                justifyContent={'center'}
                                alignItems={'center'}
                                gap={1}
                                marginBottom={'20px'}
                                key={idx}
                            >
                                <TimeLine>
                                    <Seta />
                                    <AccessTimeIcon fontSize="small" color="disabled" />
                                </TimeLine>
                                <Box marginRight={'10px'} display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'flex-start'} flexGrow={1}>
                                    <Dates>
                                        {
                                            parseInt(value) === 0 ? (
                                                <Typography variant="body2">
                                                    {t('TransactionsDetails.generationDate')}
                                                </Typography>) : parseInt(value) === 1 ? (
                                                    <Typography variant="body2">
                                                        {t('TransactionsDetails.capturedDate')}
                                                    </Typography>) : parseInt(value) === 2 ? (
                                                        <Typography variant="body2">
                                                            {t('TransactionsDetails.canceledDate')}
                                                        </Typography>) : ''

                                        }
                                        {
                                            parseInt(value) === 0 ? (<Date>{dayjs(history[value]?.created_at).format('DD/MM/YYYY')}</Date>) 
                                            : parseInt(value) === 1 && (<Date>{dayjs(history[value]?.payment_at).format('DD/MM/YYYY')}</Date>) 
                                        }
                                    </Dates>
                                    <StatusBody>
                                        <Typography>Status</Typography>
                                        <Status>{getStatus(history[value]?.status)}</Status>
                                    </StatusBody>
                                </Box>

                                
                            </Grid>
                        )
                        })
                        }
                    </Grid>
                </div>
            </History>
        )
    }
    

    return (
        <div>
            <Edit style={{marginBottom: '20px'}} aside={<Aside history={history} />} title={<UserTitle />}>
                <SimpleForm toolbar={<TransactionToolbar />}>
                    <TransactionsDetails />
                    {/* <HistoryButtons>
                        <EstornoTotal onClick={() => HandleEstornoTotal()}>{t('TransactionsDetails.fullChargeback')}</EstornoTotal>
                    </HistoryButtons> */}
                </SimpleForm>

                <HistoryResponsiva>
                    <Border />
                    <HistoryData />
                    <div style={{width: '100%', height: '80px', backgroundColor: '#F5F5F5', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <TransLink to="/transactions">{t('Transactions.back')}</TransLink>
                    </div>
                </HistoryResponsiva>
            </Edit>


        </div>
    )
}

export default TransactionsShow

