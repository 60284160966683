
export const SellersActionTypes = {
    GET_SELLERS_LIST: '@sellers/GET_SELLERS_LIST',
    SET_SELLERS_LIST: '@sellers/SET_SELLERS_LIST',
    GET_SELLERS_BY_ID: '@sellers/GET_SELLERS_BY_ID',
    SET_SELLERS_BY_ID: '@sellers/SET_SELLERS_BY_ID',
  };
  
  const INITIAL_STATE = {
    loading: false,
    sellers: [],
    seller: {},
  };
  
  export class sellerActions {

    static getSellersList = (sellers) => ({type: SellersActionTypes.GET_SELLERS_LIST, payload: sellers});
    static setSellersList = (sellers) => ({type: SellersActionTypes.SET_SELLERS_LIST, payload: sellers});
    static getSellersById = (seller) => ({type: SellersActionTypes.GET_SELLERS_BY_ID, payload: seller});
    static setSellersById = (seller) => ({type: SellersActionTypes.SET_SELLERS_BY_ID, payload: seller})
  }
  
  export class sellersSelectors {

    static getLoading({sellers}) {
      return sellers.loading;
    }

    static getSellersList({sellers}){
      return sellers;
    }
    
    static getSellersById({sellers}){
       return sellers.seller;
    }
  }
  
  export function reducer(state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case SellersActionTypes.GET_SELLERS_LIST:
          return {...state, sellers: payload, loading: true};
      case SellersActionTypes.SET_SELLERS_LIST:
        return {...state, sellers: payload, loading: false};
      case SellersActionTypes.SET_SELLERS_BY_ID:
          return {...state, seller: payload};  
    default:
      return state;
    }
  }
  