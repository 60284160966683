import axios from 'axios';
import {useCallback, useEffect, useState} from 'react' //eslint-disable-line
import { useDispatch } from 'react-redux';
import { TransactionActions } from 'src/pages/Transactions/store/reducer';
import { UserActions } from 'src/pages/User/store/reducer';

export function useHistory(transactionId){
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const handleHistory = useCallback(async () => {
    dispatch(UserActions.initLoading());
    try{

      const config = {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/transactions/history/${transactionId}`, config);

      dispatch(TransactionActions.asyncHistory(response?.data));
      dispatch(UserActions.endLoading());
      return response.data;
    } catch(error){
      console.log(error)
      dispatch(UserActions.endLoading());
    }
  }, [])

  return handleHistory
}