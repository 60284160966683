import React, { useState } from 'react'
import { Datagrid, DateField, DateInput, FilterButton, TextField, FunctionField, List, SelectInput, TextInput, TopToolbar, useRefresh, Pagination } from 'react-admin'
// import jsonExport from 'jsonexport/dist';
import { useSelector } from 'react-redux'; 
import { UserSelectors } from 'src/pages/User/store/reducer';
import PageNotFound from 'src/components/PageNotFound';
import { useTranslation } from 'react-i18next';
import Loading from 'src/components/Loading';
import { toCurrency } from 'src/utils/formatters';
import { useGetPermissions } from 'src/hooks/usePermissions';
import { useStatus } from 'src/hooks/useStatus';
import { useGetSelers } from 'src/hooks/useClients';
import { TransactionSelectors } from '../store/reducer';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import dayjs from 'dayjs';
import {
  ContainerFilter,
  ContainerLabel,
  ContainerItem
} from '../../../components/StyleFilters/StyleFilters.Elements'
import {
    ExportButton
} from './Trasanctions.Elements'
import { useExport } from 'src/hooks/useExport';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';



const TransactionList = () => {
const [open, setOpen] = useState(false);
const permissions = useSelector(UserSelectors.getPermissions); 
const {t} = useTranslation();
const loading = useSelector(UserSelectors.getLoading);
const loadingExport = useSelector(TransactionSelectors.getLoadingExport); 
const getStatus = useStatus();
const handleSelers = useGetSelers();
const handleExport = useExport();
const sellers = useSelector(TransactionSelectors.getSellers); //eslint-disable-line
const linkExport = useSelector(TransactionSelectors.getExport);

const handlePermissions = useGetPermissions();
const sellersArray = [];
sellers.map(seller => {
    sellersArray.push({
    id: seller,
    name: seller
    })
});

const handleOpen = () => {
    setOpen(true);
};

const handleClose = () => {
    setOpen(false);
};

const refresh = useRefresh();

const style = {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
    pt: 2,
    px: 4,
    pb: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '8px'
  };

  const downloadXLSX = (url, name) => {
    var link = document.createElement("a");
    link.download = name;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const actionExport = () => {
    handleExport();
    handleOpen()
  }

const ListActions = () => {
    
return(
    <TopToolbar style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <FilterButton />
        <ExportButton onClick={actionExport}>
            <ArrowDownwardIcon fontSize="small" style={{marginRight: '2px'}} />
            <span>EXPORT</span>
        </ExportButton>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{ ...style, width: 400 }}>
                <h2 id="parent-modal-title">Gerando relatório de transações</h2>
                <Box sx={{ display: 'flex', marginTop: '20px' }}>
                    {loadingExport ? <CircularProgress /> : 
                    // <ExportButton href={linkExport}>
                    //     <ArrowDownwardIcon fontSize="small" style={{marginRight: '2px'}} />
                    //     <span>EXPORTAR</span>
                    // </ExportButton>
                    open && downloadXLSX(linkExport, 'Transações.xlsx')
                    }

                    
                </Box>
                {loadingExport ? '' : <span>Seu arquivo foi gerado com sucesso!</span>}
            </Box>
        </Modal>
    </TopToolbar>
  )
}

  React.useEffect(() => {
    handleSelers();
    refresh();  
    if (permissions.length === 0) {
        handlePermissions();
    } else {
        return
    }
}, []);
  const TransFilters = [
      <FunctionField alwaysOn source='id' label={t('Transactions.transactionId')} render={() => (
          <ContainerFilter>
              <ContainerLabel>{t('Transactions.transactionId')}</ContainerLabel>
              <ContainerItem>
                  <TextInput source="id" label={t('Transactions.transactionId')} alwaysOn />
              </ContainerItem>
          </ContainerFilter>
      )} />,
      <FunctionField source='clientId' label={t('Transactions.sellerId')} render={() => (
          <ContainerFilter style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginTop: '15px'}}>
              <ContainerLabel>{t('Transactions.sellerId')}</ContainerLabel>
              <ContainerItem style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <TextInput defaultValue={null} source="clientId" label={t('Transactions.sellerId')} />,
              </ContainerItem>
          </ContainerFilter>
      )} />,
      <FunctionField source='transactionType' label={t('Transactions.transactionType')} render={() => (
          <ContainerFilter style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginTop: '15px'}}>
              <ContainerLabel>{t('Transactions.transactionType')}</ContainerLabel>
              <ContainerItem style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <SelectInput emptyText={'Escolha uma opção'} source="transactionType" label={t('Transactions.transactionType')} choices={[
                      { id: 'boleto', name: t('Transactions.boleto') },
                      { id: 'pix', name: t('Transactions.pix') },
                      { id: 'lio', name: t('Transactions.lio') },
                      { id: 'cartao', name: t('Transactions.onlineCard') },
                  ]} />
              </ContainerItem>
          </ContainerFilter>
      )} />,
      <FunctionField source='salesChannel' label={t('Transactions.salesChannel')} render={() => (
          <ContainerFilter style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginTop: '15px'}}>
              <ContainerLabel>{t('Transactions.salesChannel')}</ContainerLabel>
              <ContainerItem style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <SelectInput emptyText={'Escolha uma opção'} source="salesChannel" label="Canal de Venda" choices={sellersArray} />
              </ContainerItem>
          </ContainerFilter>
    )} />,
      <FunctionField alwaysOn source='created_at_begin' label={t('Data de Criação')} render={() => (
          <ContainerFilter style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginTop: '15px'}}>
              <ContainerLabel>Data de Criação</ContainerLabel>
              <ContainerItem style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <DateInput source="created_at_begin" label="Data Inicio" />
                  <span style={{margin: '0 20px'}}> to </span>
                  <DateInput source="created_at_end" label="Data Fim" />
              </ContainerItem>
          </ContainerFilter>
      )} />,
      <FunctionField alwaysOn source='payment_at' label={t('Data do Pagamento')} render={() => (
          <ContainerFilter style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', marginTop: '15px'}}>
              <ContainerLabel>Data de Pagamento</ContainerLabel>
              <ContainerItem style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <DateInput source="payment_at_begin" label="Data Inicio" />
                  <span style={{margin: '0 20px'}}> to </span>
                  <DateInput source="payment_at_end" label="Data Fim" />
              </ContainerItem>
          </ContainerFilter>
      )} />,
];

    const rowClick = (record) => {
    if (record.commentable) {
        return 'edit';
    }
        return 'show';
    };

//   const exporter = transactions => { 
//   const transactionForExport = transactions.map(transaction => {
//   const { ...userForExport } = transaction; // omit backlinks and author

//   return userForExport;
//   });
//   jsonExport(transactionForExport, (err, csv) => {
// //   downloadCSV(csv, 'transactions');
//     linkExport
//   });
//   };

    const exporter = () => {
        
    }

  const TrnsactionsPagination = () => <Pagination rowsPerPageOptions={[10, 20, 25, 50]} />;

return (
    <div>
        {loading ? <Loading /> : permissions.includes("transactions:list") ? <List perPage={20} pagination={<TrnsactionsPagination />} actions={<ListActions />} exporter={exporter} filters={TransFilters}>
            <Datagrid size={"medium"} rowClick={permissions.includes('transactions:details') ? rowClick : ''}>
                <TextField label={t('Transactions.transactionId')} source="id" />
                <FunctionField sortBy={'amount'} label={t('Transactions.amount')} render={record => record.amount ? toCurrency(record?.amount) : '' } />
                <FunctionField sortBy={'created_at'} label={t('Transactions.created_at')} render={record => record.created_at ? dayjs(record?.created_at).utc(false).format('DD/MM/YYYY') : '' } />
                <DateField label={t('Transactions.dateUpdated')} source="updated_at" />
                <FunctionField sortBy={'payment_at'} label={t('Transactions.payment_at')} render={record => record.payment_at ? dayjs(record?.payment_at).utc(false).format('DD/MM/YYYY') : '' } />
                <FunctionField sortBy={'status'} label={t('Transactions.status')} render={
                    record => record.status && <span>{getStatus(record.status)}</span> }
                  />
                <TextField label={t('Transactions.sellerId')} source="clientId" />
                <TextField label={t('Transactions.salesChannel')} source="salesChannel" />
                <FunctionField sortBy={'transactionType'} label={t('Transactions.transactionType')} render={record => record.transactionType === 'cartao' ? 'Cartão Online' : record.transactionType} />
            </Datagrid>
        </List> : <PageNotFound />}
    </div>
)
}

export default TransactionList