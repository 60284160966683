export const TransactionActionTypes = {
  ASYNC_TRANSACTION: '@auth/ASYNC_TRANSACTION',
  ASYNC_SELLERS: '@auth/ASYNC_SELLERS',
  ASYNC_EXPORT: '@auth/ASYNC_EXPORT',
  INIT_LOADING_EXPORT: '@auth/INIT_LOADING_EXPORT',
  END_LOADING_EXPORT: '@auth/END_LOADING_EXPORT',
};

const INITIAL_STATE = {
  transaction: '',
  sellers: [],
  export: {},
  loadingExport: false
};

export class TransactionActions {
  static asyncTransaction = (payload) => ({type: TransactionActionTypes.ASYNC_TRANSACTION, payload: payload});
  static asyncSellers = (payload) => ({type: TransactionActionTypes.ASYNC_SELLERS, payload: payload});
  static asyncExport = (payload) => ({type: TransactionActionTypes.ASYNC_EXPORT, payload: payload});
  static initLoadingExport = () => ({type: TransactionActionTypes.INIT_LOADING_EXPORT, payload: true});
  static endLoadingExport = () => ({type: TransactionActionTypes.END_LOADING_EXPORT, payload: false});
}

export class TransactionSelectors {

  static getTransaction({user}) {
    return user.transaction;
  }

  static getSellers({transaction}) {
    return transaction.sellers;
  }

  static getExport({transaction}) {
    return transaction.export;
  }

  static getLoadingExport({transaction}) {
    return transaction.loadingExport
  }


}

export function reducer(state = INITIAL_STATE, {type, payload}) {
  switch (type) {
  case TransactionActionTypes.ASYNC_TRANSACTION:
    return {...state, transaction: payload};
  case TransactionActionTypes.ASYNC_SELLERS:
    return {...state, sellers: payload};
  case TransactionActionTypes.ASYNC_EXPORT:
    return {...state, export: payload};
    case TransactionActionTypes.INIT_LOADING_EXPORT:
      return {...state, loadingExport: payload};
    case TransactionActionTypes.END_LOADING_EXPORT:
      return {...state, loadingExport: payload};
    default:
    return state;
  }
}
