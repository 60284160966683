import React from 'react'
import { Grid, Box, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'; //eslint-disable-line
import { useTranslation } from 'react-i18next';
import { maskCep, formatDocument, toCurrency } from 'src/utils/formatters'; //eslint-disable-line
import dayjs from 'dayjs';
import { useRecordContext } from 'react-admin';
import { useStatus } from 'src/hooks/useStatus';
import Visa from '../../assets/icons/cards/Visa.svg'
import Master from '../../assets/icons/cards/Master.svg'
import Elo from '../../assets/icons/cards/Elo.svg'
import HiperCard from '../../assets/icons/cards/Hipercard.svg'
import {
    Flag
} from './TransactionCartao.elements'

const TransactionsCartao = ({ TransTypography, TransBox, TitleField, TransFunction, TransSpan, TableCellRight  }) => {
  const { t } = useTranslation();

  const getStatus = useStatus();


const CartData = () => {
    const record = useRecordContext();
    return(
        <div style={{width: '100%', textAlign: 'center', margin: '15px 0px 15px 0px'}}>
            <h2 align="center">Dados do Carrinho</h2>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID do Vendedor</TableCell>
                        <TableCellRight>
                            Nome do Vendedor
                        </TableCellRight>
                        <TableCellRight>
                            Valor do Item
                        </TableCellRight>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {record.cart.map((cart,idx) => (
                        <TableRow key={idx}>
                            <TableCell>
                                {cart.sellerId}
                            </TableCell>
                            <TableCellRight>{cart.sellerName}</TableCellRight>
                            <TableCellRight>{toCurrency(cart.price)}</TableCellRight>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

  return (
      <Grid container width={{ xs: '100%', xl: '100%' }} spacing={1}>
          <Grid item xs={38} md={32}>
              <TransTypography variant="h6" gutterBottom>
                  {t('Transactions.TransationData')}
              </TransTypography>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                  <TransBox margin={'10px 0'} flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TitleField>{t('Transactions.paymentId')}</TitleField>
                      <TransFunction render={record => record?.id ?
                          <TransSpan>{record?.id}</TransSpan>
                        : <span style={{opacity: 0}}>sem dados</span>
                      } 
                      />
                  </TransBox>
                  <TransBox margin={'10px 10px'} flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <TitleField>{t('Transactions.created_at')}</TitleField>
                      <TransFunction render={record => record?.created_at ?
                          <TransSpan>{dayjs(record?.created_at).format('DD/MM/YYYY')}</TransSpan>
                        : <span style={{opacity: 0}}>sem dados</span>
                      } 
                      />
                  </TransBox>
                  <TransBox margin={'10px 0'} flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <TitleField>{t('Transactions.paymentDate')}</TitleField>
                      <TransFunction render={record => record?.payment_at ?
                          <TransSpan>{dayjs(record?.payment_at).format('DD/MM/YYYY')}</TransSpan>
                        : <span style={{opacity: 0}}>sem dados</span>
                      } 
                      />
                  </TransBox>
              </Box>

              <Box display={{ xs: 'block', sm: 'flex' }}>
                  <TransBox margin={'10px 0'} flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <TitleField>{t('Transactions.salesChannel')}</TitleField>
                      <TransFunction render={record => record?.salesChannel ?
                          <TransSpan>{record?.salesChannel}</TransSpan>
                        : <span style={{opacity: 0}}>sem dados</span>
                      } 
                      />
                  </TransBox>

                  <TransBox margin={'10px 0px'} flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <TitleField>{t('Transactions.status')}</TitleField>
                      <TransFunction render={record => <TransSpan>{getStatus(record.status)}</TransSpan>} />
                  </TransBox>

                  <TransBox margin={'10px 0'} flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <TitleField>{t('Transactions.transactionType')}</TitleField>
                      <TransFunction render={record => record?.transactionType ?
                          <TransSpan>{record?.transactionType}</TransSpan>
                        : <span style={{opacity: 0}}>sem dados</span>
                      } 
                      />
                  </TransBox>

                  <TransBox margin={'10px 0px'} flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <TitleField>{t('Transactions.amount')}</TitleField>
                      <TransFunction render={record => <TransSpan>{toCurrency(record.amount)}</TransSpan>} />
                  </TransBox>
              </Box>
          </Grid>
                      
          <CartData />

          <Grid item xs={38} md={32}>
              <TransTypography variant="h6" gutterBottom>
                  {t('Card.titleCard')}
              </TransTypography>
              <Box display={{ xs: 'block', sm: 'flex' }}>
                  <TransBox margin={'15px 0px'} flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <TitleField>{t('Card.AuthorizationCode')}</TitleField>
                      <TransFunction render={record => <TransSpan>{record.cardData.AuthorizationCode}</TransSpan>} />
                  </TransBox>
                  <TransBox margin={'10px 0'} flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <TitleField>{t('Card.Brand')}</TitleField>
                      <TransFunction render={record => <TransSpan><Flag src={
                        record.cardData.Brand === 'Visa' ? Visa 
                        : record.cardData.Brand === 'Master' ? Master
                        : record.cardData.Brand === 'Elo' ? Elo
                        : record.cardData.Brand === 'HiperCard' && HiperCard
                      } /> {record.cardData.Brand}</TransSpan>} />
                  </TransBox>
              </Box>

              <Box display={{ xs: 'block', sm: 'flex' }}>
                  <TransBox margin={'10px 0'} flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <TitleField>{t('Card.ExpirationDate')}</TitleField>
                      <TransFunction render={record => <TransSpan>{record.cardData.ExpirationDate}</TransSpan>} />
                  </TransBox>
                  <TransBox margin={'10px 0'} flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <TitleField>{t('Card.NumberMask')}</TitleField>
                      <TransFunction render={record => <TransSpan>{record.cardData.Mask}</TransSpan>} />
                  </TransBox>
              </Box>

          </Grid>
      </Grid>
  )
}

export default TransactionsCartao