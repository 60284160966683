import React from 'react'
import { Grid, Box, Typography, Stepper } from '@mui/material';  //eslint-disable-line
import {
  Card,
  CardContent,
} from '@mui/material';

import AccessTimeIcon from '@mui/icons-material/AccessTime';//eslint-disable-line
import { AsideTypography, TransOptions, EstornoTotal, Seta, Line, Date, Status, TimeLine, Dates, StatusBody } from './Aside.Elements'; //eslint-disable-line
import { UserSelectors } from 'src/pages/User/store/reducer';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs'; //eslint-disable-line
import { useStatus } from 'src/hooks/useStatus';


const Aside = ({ history }) => {
  return (
      <Box width={400} display={{ xs: 'none', lg: 'block' }}>
          <EventList history={history} />
      </Box>
  )

}

  export default Aside;

  const EventList = ({ history }) => { //eslint-disable-line
    const permissions = useSelector(UserSelectors.getPermissions); //eslint-disable-line
    const {t} = useTranslation();
    const getStatus = useStatus();//eslint-disable-line
    const keys = Object.keys(history);
    

    // const HandleEstornoParcial = () => {
    //   setIsOpen(true);
    // }

    // const handleDialogClose = () => setIsOpen(false);
    //   const handleConfirm = () => {
    //       // fazer a logica do estorno parcial
    //       console.log('console parcial');
    //       notify('Estorno parcial concluído');
    //       setIsOpen(false);
    //       redirect('/transactions', true);

    //   };

    // const HandleEstornoTotal = () => {
    //   return console.log('estorno total')
    // }

    return (
        <Box ml={2}>
            <Card>
                <CardContent>
                    <AsideTypography variant="h6" gutterBottom>
                        {t('TransactionsDetails.history')}
                    </AsideTypography>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Line height={keys.length}/>
                        <Grid display="flex" flexDirection={'column'} container rowSpacing={1} columnSpacing={1}>
                            {keys.map((value, idx) => {
                                return(
                                    <Grid 
                                        item 
                                        width={'100%'}
                                        xs={0} 
                                        display="flex" 
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        gap={1}
                                        marginBottom={'20px'}
                                        key={idx}
                                    >
                                        <TimeLine>
                                            <Seta />
                                            <AccessTimeIcon fontSize="small" color="disabled" />
                                        </TimeLine>
                                        <Box marginRight={'10px'} display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'flex-start'} flexGrow={1}>
                                            <Dates>
                                                {
                                                    parseInt(value) === 0 ? (
                                                        <Typography variant="body2">
                                                            {t('TransactionsDetails.generationDate')}
                                                        </Typography>) : parseInt(value) === 1 ? (
                                                            <Typography variant="body2">
                                                                {t('TransactionsDetails.capturedDate')}
                                                            </Typography>) : parseInt(value) === 2 ? (
                                                                <Typography variant="body2">
                                                                    {t('TransactionsDetails.canceledDate')}
                                                                </Typography>) : ''

                                                }
                                                {
                                                    parseInt(value) === 0 ? (<Date>{dayjs(history[value]?.created_at).format('DD/MM/YYYY')}</Date>) 
                                                    : parseInt(value) === 1 && (<Date>{dayjs(history[value]?.payment_at).format('DD/MM/YYYY')}</Date>) 
                                                }
                                            </Dates>
                                            <StatusBody>
                                                <Typography>Status</Typography>
                                                <Status>{getStatus(history[value]?.status)}</Status>
                                            </StatusBody>
                                        </Box>
                                        
                                    </Grid>
                                )
                            })
                            }
                        </Grid>
                    </div>
                </CardContent>
            </Card>
            <Stepper orientation="vertical" sx={{ mt: 1 }}>
                {permissions.includes("transactions:reversal") && <TransOptions>
                    {/* <EstornoTotal onClick={() => HandleEstornoTotal()}>{t('TransactionsDetails.fullChargeback')}</EstornoTotal> */}
                    {/* <EstornoParcial onClick={() => HandleEstornoParcial()}>Estorno Parcial</EstornoParcial>
                    {isOpen && <Modal title="Estorno Parcial" onClose={() => setIsOpen(false)}>
                        <EstornoContainer>
                            <EstornoTitle>Insira o valor do estorno parcial</EstornoTitle>

                            <EstornoInput type="number" />

                            <EstornoButtons>
                                <EstornoButtonConfirm onClick={() => handleConfirm()}>Confirmar</EstornoButtonConfirm>
                                <EstornoButtonCancel onClick={() => handleDialogClose()}>Cancelar</EstornoButtonCancel>
                            </EstornoButtons>
                        </EstornoContainer>
                    </Modal>} */}
                </TransOptions>}
            </Stepper>
        </Box>
  )

}


