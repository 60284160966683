import { toast } from 'react-toastify';
import { call, put, all, takeLatest } from 'redux-saga/effects';
import { getSellers, getUserById } from 'src/utils/web-api'
import { sellerActions, SellersActionTypes } from './reducer';

function* sellersList() {
  try {      
      const response = yield call(getSellers);
      yield put(sellerActions.setSellersList(response));
    } catch (error) {
        toast.error('Ocorreu um erro inesperado, por favor tente mais tarde.')
    }
}

function* sellerById({payload}) {
  try {      
      const response = yield call(getUserById, payload);
      yield put(sellerActions.setSellersById(response));
    } catch (error) {
        toast.error('Ocorreu um erro inesperado, por favor tente mais tarde.')
    }
}

export default function* MySaga() {
    yield all([
      yield takeLatest(SellersActionTypes.GET_SELLERS_LIST, sellersList),
      yield takeLatest(SellersActionTypes.GET_SELLERS_BY_ID, sellerById),
    ]);
  }